<template>
  <div class="login-container">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" auto-complete="on"
      label-position="left">
      <div class="title-container">
        <img src="https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxNzc4MDI5NC4yMTM4LjU1MTIx" width="40%">
        <h3 class="title">视云格后台管理系统</h3>
      </div>
      <el-form-item prop="username" style="background-color: white;">
        <span class="svg-container">
          <i class="el-icon-user" style="color:black"></i>
        </span>
        <el-input v-model="loginForm.username" placeholder="请输入您的用户名" name="username" type="text" tabindex="1"
          auto-complete="on" />
      </el-form-item>
      <el-form-item prop="password" style="background-color: white;">
        <span class="svg-container">
          <i class="el-icon-unlock" style="color:black"></i>
        </span>
        <el-input :key="passwordType" ref="password" v-model="loginForm.password" :type="passwordType"
          placeholder="请输入您的密码" name="password" tabindex="2" auto-complete="on" @keyup.enter.native="handleLogin" />
       <span class="show-pwd" @click="showPwd">
          <i :class="passwordType === 'password' ? 'el-icon-turn-off' : 'el-icon-open'" />
        </span>
      </el-form-item>
      <el-button :loading="loading" type="primary" style="width:60%;margin-bottom:30px;background: #002451;"
        @click.native.prevent="handleLogin">登陆</el-button>
    </el-form>
  </div>
</template>
<script>
  import {
    Login
  } from '@/api/user.js'
  import md5 from 'js-md5'
  export default {
    data() {
      return {
        loginForm: {
          // username: 'qishiba123', // 学校端
          // username: '15500210032', // 教师端
          // username: '15589053854', // 代理端
          password: '',
          username: ''
          // password: '123456'
          // admin
        },
        loginRules: {
          username: [{
            required: true,
            message: '请输入用户名',
            trigger: 'blur'
          }],
          password: [{
            required: true,
            message: '请输入密码',
            trigger: 'blur'
          }]
        },
        loading: false,
        passwordType: 'password'
      }
    },
    methods: {
      showPwd() {
        if (this.passwordType === 'password') {
          this.passwordType = ''
        } else {
          this.passwordType = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },
      handleLogin() {

        this.loading = true
        const datas = {
          account: this.loginForm.username,
          password: md5(this.loginForm.password)
        }

        Login(datas)
          .then(res => {
            if (res.status.code == 1) {
              this.loading = false
              localStorage.setItem('userInfo', JSON.stringify(res.data)) // 存用户信息
              localStorage.setItem('token', res.data.token) // 存token信息
              this.$store.commit('handleInfoCode', res.data.userType)
              const Infos = res.data.userType == 'teacher' ? '/teacherHome' : (res.data.userType == 'agent' ?
                '/AgentHome' :  (res.data.userType == 'agentAdv' ?
                '/AgentHome' :  (res.data.userType == 'school' ? '/SchoolHome' : (res.data.userType ==
                  'teacherAgent' ? '/teacherHome' : '/'))))

                const tokens = encodeURIComponent(localStorage.getItem('token'));
                // const syurl = encodeURIComponent('http://192.168.1.80:8080/#/login' + "?addr=3"); //url转义 // url转义解码用 decodeURIComponent(str)

                // const syurl = encodeURIComponent('http://shiyun.dljianwei.com/html/#/login' + "?addr=3"); //测试地址

                //const syurl = encodeURIComponent('http://sygp.shiyuntech.net/html/#/login' + "?addr=3"); //正式地址
                const syurl = encodeURIComponent(this.$url+'/html/#/login' + "?addr=3"); //token失败正式地址

                // const scurl = "http://shiyunweb.dljianwei.com";//大屏测试地址
                //const scurl = "http://sygpweb.shiyuntech.net/";   //大屏正式地址

                const scurl =   this.$url == "http://sygp.shiyuntech.net" ? "http://sygpweb.shiyuntech.net/" : "http://shiyunweb.dljianwei.com";

                window.location.href =(scurl + "?token=" + tokens + "&url=" + syurl)

            } else {
              this.$message.error(res.status.msg || res.title)
              this.loading = false
            }
          })
          .catch(err => {
            this.loading = false;
            console.log(err)
          })
      }
    }
  }
</script>

<style lang="scss">
  /* 修复input 背景不协调 和光标变色 */
  /* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

  $bg:#283443;
  $light_gray:#fff;
  $cursor: #fff;

  @supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
    .login-container .el-input input {
      color: $cursor;
    }
  }

  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      width: 85%;

      input {
        background: transparent;
        border: 0px;
        -webkit-appearance: none;
        border-radius: 0px;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
        caret-color: #000000;

        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px white inset !important;
          -webkit-text-fill-color: #889aa4 !important;
        }
      }
    }

    .el-form-item {
      border: 1px solid rgba(255, 255, 255, 0.1);
      background: rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      color: #454545;
    }
  }
</style>

<style lang="scss" scoped>
  $bg:#2d3a4b;
  $dark_gray:#889aa4;
  $light_gray:#eee;

  .login-container {
    height: 100%;
    width: 100%;
    background: url('https://eyesupload.oss-cn-beijing.aliyuncs.com/MTYxNzc4MDIzMy4xODk4LjM0NjU1');
    background-size: 100% 100%;

    .login-form {
      background: white;
      width: 500px;
      height: 400px;
      border-radius: 5%;
      position: absolute;
      left: 60%;
      top: 25%;
      padding: 10px 50px;

      /deep/.el-input__inner {
        color: #889aa4 !important;
      }

      /deep/.el-form-item__error {
        margin-left: 50px;
      }

    }

    .tips {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;

      .title {
        font-size: 26px;
        color: #002451;
        margin: 0px auto 40px auto;
        text-align: center;
        font-weight: bold;
      }
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }
  }
</style>
